


































import { Aluno, Turma } from "@/core/models/geral";
import { PageBase } from "@/core/models/shared";
import { TurmaService } from "@/core/services/geral";
import {Component, Prop, Watch} from "vue-property-decorator";

@Component
export default class ConfiguracaoImpressaoChamada extends PageBase{
  
  valid: boolean = true;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  turmas: Turma[] = [];
  turmaService: TurmaService = new TurmaService();
  isTurmaLoading: boolean = false;
  onSearchTurma: any = null;
  
  alunos: Aluno[] = [];  

  filtro: any = {
    turmaId: 0,
    alunoId: 0
  }

  $refs!: {
    form: HTMLFormElement
  }

  // @Watch('onSearchTurma')
  // SearchTurma (val:string){
  //   if(this.filtro.turmaId) 
  //     return;
  //   if(this.isTurmaLoading)
  //     return;
  //   if(!val)
  //     return;

  //   this.isTurmaLoading = true;
  //   this.turmaService.AutoComplete(val).then(
  //     res =>{
  //       this.turmas = res.data;
  //     },
  //     err => this.$swal('Aviso',err.response.data,'error')
  //   ).finally(() =>{
  //     this.isTurmaLoading = false;
  //   })
  // }

  @Watch('filtro.turmaId')
  WatchSelectTurma(){    
    if(this.filtro.turmaId > 0){
      this.turmaService.ObterPorId(this.filtro.turmaId, "Alunos.Aluno").then(
        res => {
          var turma = res.data;
          this.alunos = [];
          this.filtro.alunoId = 0;
          turma.alunos.forEach(aluno => {
            this.alunos.push(aluno.aluno);
          });
        },
        err => {
          if (!err.response){
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          }
          else{
            this.$swal("Aviso",err.response.data,"error")
          }
        }
      )
    }
    else{
      return;
    }    
  }

  mounted() {
    this.turmaService.ListarTudo("Curso").then(
      res => {
        this.turmas = res.data.items;
      },
      err =>{
        if (!err.response){
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        }
        else{
          this.$swal("Aviso",err.response.data,"error")
        }
      }
    )
  }
    
  GerarRelatorio(){
    let routeLink = this.$router.resolve({name:"impressaoCashBack",
      query:{
        turmaId: this.filtro.turmaId,
        alunoId: this.filtro.alunoId,
      }
    });
    window.open(routeLink.href,'_blank')
  }

  LimparFiltros(){
    this.filtro.turmaId = 0;
    this.filtro.alunoId = 0;
    this.$refs.form.resetValidation();
  }
}

